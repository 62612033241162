import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { apiurl } from "../config/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/slices/diagnosPatient";
import SkeletonContainer from "../routes/skeleton";
import { useFormatOnlyDate } from "../hooks/useFormatDate";
import moment from "moment";
import { GoDownload } from "react-icons/go";
import ExportDataHandler from "../components/exportDataHandler";
import ReactPaginate from "react-paginate";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "./confirmModal";

const Diagnosis = () => {
  const [diagnosData, setDiagnosData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState({ from: "", till: "" });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 50;
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/diagnose/getall`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setDiagnosData(response.data.data || []); 
          setFilteredData(response.data.data || []);
          dispatch(fetchData(response.data.data || []));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    let formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
    return formattedDate.split("-").reverse().join("-");
  };

  const FormateDate = (date) => useFormatOnlyDate(date);

  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  useEffect(() => {
    if (date.from && date.till) {
      FilterHandler();
    }
  }, [date]);

  const FilterHandler = () => {
    if (diagnosData.length > 0) {
     
      const fromDate = new Date(date.from);
      const tillDate = new Date(date.till);

      const filtered = diagnosData.filter((item) => {
        const diagnosisDate = new Date(
          moment(item?.diagonse_date).format("YYYY-MM-DD")
        );
        return diagnosisDate >= fromDate && diagnosisDate <= tillDate;
      });

      setFilteredData(filtered);
      setCurrentPage(0);
    }
  };
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      setShowModal(true); // Show the confirmation modal for multiple deletions
    }
  };

  const confirmDelete = () => {
    if (userIdToDelete) {
      // Single deletion
      axios
        .post(`https://api.thehealthspanco.com/api/v1/diagnose/delete/${userIdToDelete}`)
        .then(() => {
          setDiagnosData((prevData) => prevData.filter((item) => item._id !== userIdToDelete));
          setFilteredData((prevData) => prevData.filter((item) => item._id !== userIdToDelete));
          setShowModal(false); // Close the modal after deletion
        })
        .catch((error) => {
          console.log(error);
          setShowModal(false); // Close modal even in case of error
        });
    } else {
      // Multiple deletions
      selectedRows.forEach((userId) => {
        axios
          .post(`https://api.thehealthspanco.com/api/v1/diagnose/delete/${userId}`)
          .then(() => {
            setDiagnosData((prevData) => prevData.filter((item) => item._id !== userId));
            setFilteredData((prevData) => prevData.filter((item) => item._id !== userId));
          })
          .catch((error) => console.log(error));
      });
      setSelectedRows([]); // Reset selected rows after deletion
      setShowModal(false); // Close modal after deletion
    }
  };

  const handleDelete = (userId) => {
    setUserIdToDelete(userId); // Set the user ID for single deletion
    setShowModal(true); // Show the modal for single deletion
  };

  const cancelDelete = () => {
    setShowModal(false); // Close the modal without performing any action
  };

  const startIndex = currentPage * itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (event) => setCurrentPage(event.selected);
  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    return payload.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        date: item.date,
        age: item.age,
        gender: item.gender,
        weight: item.weight,
        height: item.height,
        health_goals: Array.isArray(item.health_goals)
          ? item.health_goals.join(", ")
          : "",
        bmi_category: item.bmi?.category || "",
        bmi_range: item.bmi?.range || "",
        gut_symptom: Array.isArray(item.gut_symptom)
          ? item.gut_symptom.join(", ")
          : "",
        exercise: item.exercise || "",
        phyiscal_activity: item.phyiscal_activity || "",
        medical_condition: item.medical_condition || "",
        root_cause: Array.isArray(item.root_cause)
          ? item.root_cause.join(", ")
          : "",
        bmr: item.bmr || "",
        idealWeight: item.idealWeight || "",
        weightToLose: item.weightToLose || "",
        weightLossDuration: item.weightLossDuration || "",
        idealWaistSize: item.idealWaistSize || "",
        dailyCalorieIntake: item.dailyCalorieIntake || "",
        diagnosed_conditions: Array.isArray(item.diagnosed_conditions)
          ? item.diagnosed_conditions.join(", ")
          : "",
        eating_habits: item.eating_habits || "",
        allergic: Array.isArray(item.allergic) ? item.allergic.join(", ") : "",
        medication: item.medication || "",
        sleep: item.sleep || "",
        diagonse_date: item.diagonse_date || "",
        description: item.description || "",
        createdAt: item.createdAt || "",
        updatedAt: item.updatedAt || "",
      };
    });
  };
  return (
    <Layout>
      <div className="w-full mt-2 h-full">
        <div className="my-3 mt-6 flex  relative ">
          {selectedRows.length > 0 && (
            <div className="flex items-center">
              <button
                className="text-white bg-green-800 px-2 py-1 rounded cursor-pointer ms-2"
                onClick={handleDeleteSelected}
              >
                Delete
              </button>
            </div>
          )}

          {showModal && (
            <ConfirmModal
              message={
                selectedRows.length > 0
                  ? `Are you sure you want to delete ${selectedRows.length} user(s)?`
                  : "Are you sure you want to delete this user?"
              }
              onConfirm={confirmDelete}
              onCancel={cancelDelete}
            />
          )}

          <div className="flex items-center justify-end fixed right-0">
            <div className="flex gap-3 max-[375px]:justify-between">
            <input
                placeholder="From"
                name="from"
                type="date"
                max={getCurrentDate()}
                className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-1 md:px-3 lg:px-5 py-2 w-full md:w-auto"
                onChange={dateHandler}
                value={date.from}
              />
              <input
                placeholder="Till"
                name="till"
                type="date"
                min={date.from}
                max={getCurrentDate()}
                className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-1 md:px-3 lg:px-5 py-2 w-full md:w-auto"
                onChange={dateHandler}
                value={date.till}
              />
             <button
              className={`border p-2 px-4 ms-2 rounded-md ${
                date.from && date.till && filteredData.length > 0
                  ? "bg-green-50 border-green-800 text-green-800"
                  : "border-green-800 text-green-800"
              }`}
              onClick={() => ExportDataHandler(ExportPayload(filteredData))}
              disabled={!(date.from && date.till && filteredData.length > 0)}
            >
              <GoDownload />
            </button>
            </div>
          </div>
        </div>
        <div className="h-full overflow-scroll text-xs md:text-sm lg:text-base px-5 mt-12 pb-36">
        <table className="border-collapse w-full relative table-auto overflow-auto border ">
          <thead className="bg-green-800 border-y text-white lg:text-base text-xs sticky top-0">
            <tr>
              <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Select</th>
              <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Name
                </th>
                <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Email
                </th>
                <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Phone
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Date
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  B.M.R
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  B.M.I
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Purchase
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Action
                </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((user) => (
              <tr key={user._id}>
                <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                  <input
                    type="checkbox"
                    className="scale-125"
                    checked={selectedRows.includes(user._id)}
                    onChange={() => handleSelectRow(user._id)}
                  />
                </td>
                <td
                        className="px-2 py-1 sm:px-4 md:px-6 text-center"
                        onClick={() =>
                          navigate(`/diagnosis/${user._id}`, {
                            state: { patient: user },
                          })
                        }
                      >
                        {user?.name}
                      </td>
                      <td
                        className="px-2 py-1 sm:px-4 md:px-6 text-center"
                        onClick={() =>
                          navigate(`/diagnosis/${user._id}`, {
                            state: { patient: user },
                          })
                        }
                      >
                        {user?.email}
                      </td>
                      <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                        {user?.phone}
                      </td>
                      <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                        {FormateDate(user?.diagonse_date)}
                      </td>
                      <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                        {user?.bmr}
                      </td>
                      <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                        {user?.bmi?.range}
                      </td>
                      <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                        <span
                          className={
                            user?.orderDetails?.purchase
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {user?.orderDetails?.purchase ? "Yes" : "No"}
                        </span>
                      </td>
                <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                  <button
                    onClick={() =>
                          navigate(`/diagnosis/${user._id}`, {
                            state: { patient: user },
                          })
                        }
                    className="text-black"
                  >
                    <FaPencilAlt />
                  </button>
                  <button
                    onClick={() => handleDelete(user._id)}
                    className=" hover:text-red-600 ml-3"
                  >
                    <MdDelete />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        

        <div className="py-5 px-10 fixed bottom-1 flex justify-center left-7 bg-white w-full h-16">
          {filteredData && (
            <div className="">
              <ReactPaginate
                pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active bg-green-800 lg:p-2 py-0.5 lg:px-4 px-2 rounded text-white"
                previousLabel={"Previous"}
                nextLabel={"Next"}
                nextClassName="hover:bg-green-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-green-800 border border-green-800 "
                previousClassName="hover:bg-green-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-green-800 border border-green-800"
                pageClassName="lg:p-2 py-0.5 lg:px-4 px-2 text-sm lg:text-base"
                className="flex lg:gap-7 md:gap-5 gap-3"
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Diagnosis;
