import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../config/config";
import { MdDelete, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";

const TeamList = () => {
  const userData = useSelector((state) => state?.userData);
  const userId = useSelector((state) => state?.userData._id);
  console.log("userId", userId);
  console.log("userdata", userData);
  const navigate = useNavigate();
  const [teamlist, setTeamist] = useState([]);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/user/getall`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setTeamist(response.data.users);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleDelete = (Id, userRole) => {
    if (Id !== userId) {
      let data = "";

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api.thehealthspanco.com/api/v1/user/delete/${Id}`,
        headers: {},
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          setTeamist((prevList) => prevList.filter((user) => user._id !== Id));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Layout>
      <SkeletonContainer />
      <div className="h-[75%] overflow-scroll text-xs md:text-sm lg:text-base px-5 ">
        <table className="border-collapse w-full relative table-auto overflow-auto border mt-2">
          <thead className="bg-green-800 border-y text-white lg:text-base text-xs sticky top-0">
            <tr>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Name
              </th>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Email
              </th>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Phone No.
              </th>
              <th
                className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Age
              </th>
              <th
                className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Role
              </th>
              <th
                className=" sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Status
              </th>
              <th
                className=" sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-zinc-500 px-10 ">
            {teamlist && teamlist.length > 0
              ? teamlist.map((item, index) => (
                  <tr
                    key={index}
                    className="hover:text-black cursor-pointer hover:bg-green-50/50 capitalize"
                  >
                    <td
                      className="py-2 sm:py-3 text-center"
                      style={{ paddingLeft: 10 }}
                      onClick={() => navigate(`/team/${item._id}`)}
                    >
                      {item?.firstname} {item?.lastname}
                    </td>
                    <td
                      className="py-2 sm:py-3 text-center"
                      style={{ paddingLeft: 10 }}
                      onClick={() => navigate(`/team/${item._id}`)}
                    >
                      {item?.email}
                    </td>
                    <td
                      className="py-2 sm:py-3 text-center"
                      style={{ paddingLeft: 10 }}
                      onClick={() => navigate(`/team/${item._id}`)}
                    >
                      {item?.phone}
                    </td>
                    <td
                      className="py-2 sm:py-3 text-center"
                      style={{ paddingLeft: 10 }}
                      onClick={() => navigate(`/team/${item._id}`)}
                    >
                      {item?.age}
                    </td>
                    <td
                      className="py-2 sm:py-3 text-center"
                      style={{ paddingLeft: 10 }}
                      onClick={() => navigate(`/team/${item._id}`)}
                    >
                      {item?.role}
                    </td>
                    <td
                      className={`py-2 sm:py-3 text-center ${
                        item?.status === "active"
                          ? "text-green-500"
                          : item?.status === "pending"
                          ? "text-yellow-500"
                          : "text-red-500"
                      }`}
                      style={{ paddingLeft: 10 }}
                      onClick={() => navigate(`/team/${item._id}`)}
                    >
                      {item?.status}
                    </td>
                    <td
                      className="py-2 sm:py-3 text-xl text-center text-black"
                      style={{ paddingLeft: 10 }}
                    >
                      <div className="flex items-center pe-4">
                        <MdEdit
                          className="m-auto"
                          onClick={() => navigate(`/team/${item._id}/edit`)}
                        />
                        <MdDelete
                          onClick={() => handleDelete(item._id, item.role)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              : [...Array(5)].map((_, key) => (
                  <tr key={key}>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default TeamList;
