import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import axios from "axios";
import { apiurl } from "../config/config.js";
import { RiDeleteBinLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";
import moment from "moment";

import ConfirmModal from "./confirmModal";
import { MdDelete } from "react-icons/md";
const Sessions = () => {
  const [sessionData, setSessionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;
  const [filteredData, setFilteredData] = useState(null);
  const [wantDelete, setWantDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiurl}/api/v1/session/getall`);
        if (response.data.success) {
          setSessionData(response.data.data);
          setFilteredData(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleSelectRow = (sessionId) => {
    if (selectedRows.includes(sessionId)) {
      setSelectedRows(selectedRows.filter((id) => id !== sessionId));
    } else {
      setSelectedRows([...selectedRows, sessionId]);
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === filteredData?.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredData?.map((item) => item?.sessionId));
    }
  };

  const handleBulkDelete = async () => {
    try {
      if (selectedRows.length > 0) {
        await axios.delete(`${apiurl}/api/v1/session/deleteall`, {
          data: { sessionIds: selectedRows },
        });

        const updatedSessionData = sessionData.filter(
          (item) => !selectedRows.includes(item?.sessionId)
        );
        setSessionData(updatedSessionData);
        setFilteredData(updatedSessionData);
        setSelectedRows([]);
        setWantDelete(false);
      }
    } catch (error) {
      console.error("Error deleting sessions:", error);
      setWantDelete(false);
    }
  };

  const handleDelete = (id) => {
    setWantDelete(true);
    setDeleteId(id);
  };

  const confirmDelete = async () => {
    try {
      if (deleteId) {
        await axios.delete(`${apiurl}/api/v1/session/delete`, {
          data: { sessionId: deleteId },
        });

        const updatedSessionData = sessionData.filter(
          (item) => item?.sessionId !== deleteId
        );
        setSessionData(updatedSessionData);
        setFilteredData(updatedSessionData);
      } else if (selectedRows.length > 0) {
        await axios.delete(`${apiurl}/api/v1/session/deleteall`, {
          data: { sessionIds: selectedRows },
        });

        const updatedSessionData = sessionData.filter(
          (item) => !selectedRows.includes(item?.sessionId)
        );
        setSessionData(updatedSessionData);
        setFilteredData(updatedSessionData);
        setSelectedRows([]);
      }
      setWantDelete(false);
      setDeleteId(null);
    } catch (error) {
      console.error("Error deleting session(s):", error);
    }
  };

  const cancelDelete = () => {
    setWantDelete(false);
    setDeleteId(null);
  };

  const startIndex = currentPage * itemsPerPage;
  const paginatedData = filteredData?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const FormateDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };

  return (
    <Layout>
      <div className="h-full overflow-y-auto text-xs md:text-sm lg:text-base px-5 mt-2 relative pb-24">
        {/* Bulk delete button */}
        {selectedRows.length > 0 && (
          <div className="flex justify-end mb-2 mt-5">
            <button
              className="p-2 px-6 bg-green-800 text-white rounded-md"
              onClick={() => setWantDelete(true)}
            >
              Delete Selected ({selectedRows.length})
            </button>
          </div>
        )}

        <table className="border-collapse w-full table-auto border ">
          <thead className="bg-green-800 border text-white lg:text-base text-xs sticky top-0">
            <tr>
              {/* Select All Checkbox */}
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">
                <input
                  type="checkbox"
                  className="scale-125"
                  checked={selectedRows.length === filteredData?.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">Name</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">Email</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">
                Phone No.
              </th>
              <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
                Age
              </th>
              <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
                Gender
              </th>
              <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
                Height
              </th>
              <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center">
                Date
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="text-zinc-500 px-10 text-center">
            {paginatedData && paginatedData.length > 0
              ? paginatedData.map((item, index) => (
                  <tr
                    key={index}
                    className="hover:text-black cursor-pointer hover:bg-green-50/50 capitalize"
                  >
                    <td className="px-4 py-2 sm:py-3 sm:px-6 text-center ">
                      <input
                        type="checkbox"
                        className="scale-125"
                        checked={selectedRows.includes(item?.sessionId)}
                        onChange={() => handleSelectRow(item?.sessionId)}
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      {item?.data?.name}
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      {item?.data?.email}
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      {item?.data?.phone}
                    </td>
                    <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                      {item?.data?.age}
                    </td>
                    <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                      {item?.data?.gender}
                    </td>
                    <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                      {item?.data?.height}
                    </td>
                    <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                      {FormateDate(item?.createdAt)}
                    </td>
                    <td className="text-center flex">
                      <MdDelete
                        className="text-black m-auto hover:bg-red-50 border border-transparent hover:border-red-300 p-2 text-4xl rounded-lg"
                        onClick={() => handleDelete(item?.sessionId)}
                      />
                    </td>
                  </tr>
                ))
              : [...Array(5)].map((_, key) => (
                  <tr key={key}>
                    {[...Array(9)].map((__, i) => (
                      <td key={i} className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer data="loading..." />
                      </td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>

        {/* Confirm Modal */}
        {wantDelete && (
          <ConfirmModal
            message={
              selectedRows.length > 0
                ? `Are you sure you want to delete ${selectedRows.length} user(s)?`
                : "Are you sure you want to delete this user?"
            }
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
          />
        )}

        {/* Pagination */}
        <div className="py-5 px-10 fixed bottom-1 flex justify-center left-7 bg-white w-full h-16">
          {filteredData && (
            <div className="">
              <ReactPaginate
                pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active bg-green-800 lg:p-2 py-0.5 lg:px-4 px-2 rounded text-white"
                previousLabel={"Previous"}
                nextLabel={"Next"}
                nextClassName="hover:bg-green-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-green-800 border border-green-800 "
                previousClassName="hover:bg-green-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-green-800 border border-green-800"
                pageClassName="lg:p-2 py-0.5 lg:px-4 px-2 text-sm lg:text-base"
                className="flex lg:gap-7 md:gap-5 gap-3"
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Sessions;
